<template>
  <section>
    <div class="flex flex-no-wrap">
      <Nav />

      <section class="enterprise-setup h-100 p-3 pb-5">
        <div class="container mb-2">
          <div class="d-flex align-items-center justify-content-center h-100 mt-3 mb-3 pt-2 pb-2">
            <div class="d-flex align-items-center me-auto">
              <div class="flex-shrink-0">
                <img
                  src="@/assets/images/logo.png"
                  class="logo"
                  alt="Relatus"
                >
              </div>
              <div class="flex-grow-1 ms-3">
                <h2><span class="font-normal">Your</span> Relatus Profile</h2>
              </div>
            </div>
          </div>
        </div>

        <div class="container container-card h-100 p-2">
          <div class="row">
            <div class="col-12 text-start">
              <div class="d-flex align-items-center border-light-gray ps-4 pe-4" style="border: 1px solid rgba(3, 29, 90, 0.2);box-sizing: border-box;border-radius: 12px;margin: 20px;">
                <div class="flex-shrink-0">
                  <img
                    src="@/assets/images/icons/undraw_People_search_re_5rre.svg"
                    alt="Enterprise Setup"
                    style="max-height: 70px;"
                  >
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="text align-self-center me-auto fw-light" style="color: #323232;">
                    Let’s take a deeper look at things that interest you.
                  </h5>
                  <p class="text align-self-center text-start" style="color: #999799;">
                    Step 3: Add your interest. We’re mote than just what we’ve done, our interest also help define who we are.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container h-100 mt-5 p-0">
          <div class="row">
            <div class="col-12">
              <p style="font-weight: lighter;font-size: 26px;line-height: 36px;">Congratulations for completing your rProfile. Let’s check it out....blah blah blah, need copy here</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Nav from '@/components/nav/nav.vue';
export default {
  name: 'ProfileCompleted',
  components: {
    Nav
  }
}
</script>

<style scoped src="@/css/bootstrap.min.css"></style>

<style scoped>
.enterprise-setup {
  background-color: #EDEDED;
}
.enterprise-setup h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.enterprise-setup h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #142328;
  letter-spacing: 1px;
}
.enterprise-setup h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-top: 20px;
  max-height: 30px;
}
.enterprise-setup .container:first-child > .d-flex {
  border-bottom: 0.5px solid #332C54;
}
.logo {
  max-height: 33px!important;
}
.enterprise-setup img {
  height: 125px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.container-card {
  padding: 40px 40px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
button {
  background-color: #FFFFFF!important;
  border: 1px solid #83C03B;
  box-sizing: border-box;
  border-radius: 30px;
  display: inline-block;
}
button:hover {
  color: #83C03B!important;
  border: 1px solid #83C03B!important;
}
a {
  text-decoration: none;
}
.tag {
  display: inline-block;
  padding: 5px;
  background: rgba(3, 29, 90, 0.2);
  border-radius: 3px;
  margin: 0px 5px;
  color: #1E1E1E;
  font-size: 14px;
}
.tag.active {
  background: #83C03B;
  color: #F9F9F9;
}
</style>
